<template>
  <div class="addBrand">
    <frame :top1="top1" :top2="top2">
      <div class="form">
        <Form
          ref="formValidate"
          :model="formValidate"
          :rules="ruleValidate"
          :label-width="80"
        >
          <FormItem label="品牌名称" prop="name">
            <Input
              v-model="formValidate.name"
              placeholder="请输入品牌名称"
            ></Input>
          </FormItem>
          <!-- <FormItem label="排序值">
						<Input v-model="formValidate.paixu" placeholder="请输入排序值"></Input>
					</FormItem> -->

          <el-form ref="form" :model="formValidate" label-width="80px">
            <el-form-item label="首字母">
              <el-select
                v-model="formValidate.city"
                prop="city"
                placeholder="请输入品牌首字母"
              >
                <el-option
                  :value="item"
                  v-for="(item, index) in initial"
                  :key="index"
                  >{{ item }}</el-option
                >
              </el-select>
            </el-form-item>
          </el-form>
          <el-upload
            class="avatar-uploader"
            :action="uploadingUrl"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过500kb，建议图片大小350*200px
            </div>
          </el-upload>
          <FormItem label="品牌故事" prop="desc">
            <Input
              v-model="formValidate.desc"
              type="textarea"
              :autosize="{ minRows: 15, maxRows: 20 }"
              placeholder="请输入品牌故事"
            ></Input>
          </FormItem>
          <FormItem label="是否显示" prop="audit">
            <RadioGroup v-model="formValidate.audit">
              <Radio label="是"></Radio>
              <Radio label="否"></Radio>
            </RadioGroup>
          </FormItem>
          当品牌下还没有商品的时候，分类页的品牌区将不会显示该品牌
          <FormItem>
            <Button type="primary" @click="handleSubmit('formValidate')">{{
              addChange
            }}</Button>
            <Button
              @click="handleReset('formValidate')"
              style="margin-left: 8px"
              >清除</Button
            >
          </FormItem>
        </Form>
      </div>
    </frame>
  </div>
</template>

<script>
import frame from "../public/Frame.vue";
import list from "../template/List.vue";
export default {
  name: "addBrand",
  data() {
    return {
      uploadingUrl: "",
      top1: "2-5",
      top2: ["2"],
      imageUrl: "",
      logo: "",
      addChange: "添加",
      initial: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      formValidate: {
        name: "",
        mail: "",
        city: "",
        gender: "",
        interest: [],
        date: "",
        time: "",
        desc: "",
        audit: "是",
        // paixu: ''
      },
      ruleValidate: {
        name: [
          {
            required: true,
            message: "请输入品牌名称",
            trigger: "blur",
          },
        ],
        mail: [
          {
            required: true,
            message: "Mailbox cannot be empty",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Incorrect email format",
            trigger: "blur",
          },
        ],
        city: [
          {
            required: true,
            message: "请选择品牌首字母",
            trigger: "blur",
          },
        ],
        gender: [
          {
            required: true,
            message: "Please select gender",
            trigger: "change",
          },
        ],
        interest: [
          {
            required: true,
            type: "array",
            min: 1,
            message: "Choose at least one hobby",
            trigger: "change",
          },
          {
            type: "array",
            max: 2,
            message: "Choose two hobbies at best",
            trigger: "change",
          },
        ],
        date: [
          {
            required: true,
            type: "date",
            message: "Please select the date",
            trigger: "change",
          },
        ],
        time: [
          {
            required: true,
            type: "string",
            message: "Please select time",
            trigger: "change",
          },
        ],
        desc: [
          {
            required: true,
            message: "请输入品牌故事",
            trigger: "blur",
          },
        ],
      },
      isshow: true,
    };
  },
  created() {
    this.uploadingUrl = this.$request.imgUrl() + "PHPExcel/fileUpload";
    let token = this.$storage.getLocal("token");
    if (this.$request.getUrl("id")) {
      // console.log(this.$request.getUrl('id'))
      this.isshow = false;
      this.addChange = "修改";
      this.$request
        .brandOneData({ token, id: this.$request.getUrl("id") })
        .then((res) => {
          console.log(res);
          this.formValidate.name = res.data.b_name;
          this.formValidate.paixu = res.data.b_order;
          this.formValidate.city = res.data.b_Initials;
          this.formValidate.desc = res.data.b_content;
          this.logo = res.data.b_logo;
          this.formValidate.audit = res.data.b_show == 1 ? "是" : "否";
          this.imageUrl =
            this.$request.imgUrl2() + "uploads/" + res.data.b_logo;
          // console.log(res.data)
        });
    }
  },
  props: {},
  components: {
    frame,
  },
  methods: {
    handleSubmit(name) {
      if (this.isshow) {
        this.$refs[name].validate((valid) => {
          if (valid) {
            let token = this.$storage.getLocal("token");
            this.$request
              .brandAdd({
                token,
                name: this.formValidate.name,
                initials: this.formValidate.city,
                logo: this.logo,
                content: this.formValidate.desc,
                show: this.formValidate.audit == "是" ? 1 : 2,
                order: 100,
              })
              .then((res) => {
                console.log(res);
                const h = this.$createElement;
                this.$notify({
                  title: "消息提示",
                  message: h(
                    "i",
                    { style: "color: red;font-size:28px" },
                    "添加成功"
                  ),
                });
								this.$router.push({path:'/brand'});
              });
          } else {
            this.$Message.error("Fail!");
          }
        });
      } else {
        this.$refs[name].validate((valid) => {
          if (valid) {
            let token = this.$storage.getLocal("token");
            this.$request
              .brandUp({
                token,
                name: this.formValidate.name,
                initials: this.formValidate.city,
                logo: this.logo,
                content: this.formValidate.desc,
                show: this.formValidate.audit == "是" ? 1 : 2,
                order: this.formValidate.paixu,
                id: this.$request.getUrl("id"),
              })
              .then((res) => {
                console.log(res);
                const h = this.$createElement;
                this.$notify({
                  title: "消息提示",
                  message: h(
                    "i",
                    { style: "color: red;font-size:28px" },
                    "修改成功"
                  ),
                });
								this.$router.push({path:'/brand'});
              });
          } else {
            this.$Message.error("Fail!");
          }
        });
      }
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      // console.log(res.data.src)
      this.logo = res.data.src;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>


<style scoped="scoped">
.form {
  width: 40%;
  padding-top: 80px;
  padding-left: 199px;
  margin: 0 auto;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.avatar-uploader-icon {
  border: 1px solid #333333;
}
</style>
